import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, catchError, map, of, throwError } from 'rxjs';
import { NgxSpinnerService } from 'ngx-spinner';

@Injectable({
  providedIn: 'root'
})
export class MainService {

  private tacticsSubject = new BehaviorSubject<any[]>([]);
  private dataLoaded = false;

  constructor(private http: HttpClient, private spinner: NgxSpinnerService) { }

  getSkills(){
    return this.http.get<{ data: string[] }>(`labs/skills`).pipe(
      map(({ data }) => {
        return data;
      })
    );
  }

  getLabsData(paramsObj?: { [key: string]: string }): Observable<any> {
    let params = new HttpParams();
    if (paramsObj) {
        for (const key in paramsObj) {
            if (paramsObj.hasOwnProperty(key) && paramsObj[key]) {
                params = params.set(key, paramsObj[key]);
            }
        }
    }
    return this.http.get('labs/all', { observe: 'response', params }).pipe(
      map((resp) => {
        const body = { data: [], ...resp?.body };
        const isAdmin = resp?.headers.get('isadmin') === 'true';
        const isTestMode = resp?.headers.get('istestmode') === 'true';
        return { data: body.data, isAdmin, isTestMode }
      })
    );
  }

  getLabWithId(labId: string): Observable<any> {
    return this.http.get<{ data: any[] }>(`labs/${labId}`, { observe: 'response' }).pipe(
      map((resp) => {
        const { data = [] } = resp.body || {};
        const logo = resp.headers.get('logo');
        const timeline = resp.headers.get('timeline');
        const trustscore = resp.headers.get('trustscore');
        return { ...data, logo, timeline, trustscore };
      })
    );
  }

  createLab(name?:any,isLive?:any,obj?:any){
    const body = obj ?? { name, isLive };
    return this.http.post<{ data: string[]; }>(`labs`, body).pipe(
      map(({ data }) => {
        this.spinner.hide()
        return data;
      }),
      catchError(error => {
        this.spinner.hide();
        console.error('Error occurred:', error);
        return throwError(() => new Error(error?.error?.msg ? error.error.msg :  'Please try again later.'));
      })
    );
  }

  startLabs(labId: string): Observable<any> {
    this.spinner.show()
    let body = {}
    return this.http.post<{ data: string[]; }>(`labs/${labId}/start`, body).pipe(
      map(({ data }) => {
        this.spinner.hide()
        return data;
      }),
      catchError(error => {
        this.spinner.hide();
        console.error('Error occurred:', error);
        return throwError(() => new Error(error?.error?.msg ? error.error.msg :  'Failed to start lab. Please try again later.'));
      })
    );
  }

  connectToLab(labId: string, instance: string): Observable<any> {
    let body = {};
    return this.http.post<{ data: string[]; }>(`labs/${labId}/connect/${instance}`, body).pipe(
      map(({ data }) => {
        return data;
      }),
      catchError(error => {
        this.spinner.hide();
        console.error('Error occurred:', error);
        return throwError(() => new Error(error?.error?.msg ? error.error.msg :'Failed to connect. Please try again later.'));
      })
    );
  }

  connectToStoppedLab(labId: string, instance: string): Observable<any> {
    this.spinner.show()
    return this.http.get<{ data: string[]; }>(`labs/${labId}/connect/${instance}`).pipe(
      map(({ data }) => {
        this.spinner.hide()
        return data;
      }),
      catchError(error => {
        this.spinner.hide();
        console.error('Error occurred:', error);
        return throwError(() => new Error(error?.error?.msg ? error.error.msg :'Failed to connect. Please try again later.'));
      })
    );
  }

  evaluate(labId: string, connectionId: string, taskId: string, questionId: string, answer: string): Observable<any> {
    const body = { answer }
    return this.http.post<{ data: string[]; }>(`labs/${labId}/evaluate/${connectionId}/task/${taskId}/question/${questionId}`, body).pipe(
      map(({ data }) => {
        return data;
      })
    );
  }

  stopLab(labId: string, instanceId: string): Observable<any> {
    return this.http.request<{ data: string[] }>('delete', `labs/${labId}/stop/${instanceId}`, {
      body: {}
    }).pipe(
      map(response => response.data)
    );
  }
  removeConnection(labId: string, connectionId: string): Observable<any> {
    return this.http.delete<{ data: any }>(`labs/${labId}/remove/${connectionId}`).pipe(
      map(response => response.data)
    );
  }
  removeLab(labId: string): Observable<any> {
    return this.http.delete<{ data: any }>(`labs/${labId}/remove`).pipe(
      map(response => response.data)
    );
  }

  submitRating(connectionId: string, body:Object): Observable<any> {
    return this.http.post<{ data: string[]; }>(`labs/${connectionId}/rating`, body).pipe(
      map(({ data }) => {
        return data;
      })
    );
  }

  getSheetData(): Observable<any> {
    const url = "assets/sheetData.json"
    return this.http.get<any>(url);
  }

  getTacticsAndTechniques(selectedTactic?: string | null): Observable<{ tactics: any[], techniques: any[] }> {
    if (this.dataLoaded) {
      const data = this.tacticsSubject.getValue();
      return of(this.processTacticsAndTechniques(data, selectedTactic || null));
    } else {
      return this.getSheetData().pipe(
        map((data: any[]) => {
          this.tacticsSubject.next(data);
          this.dataLoaded = true;
          return this.processTacticsAndTechniques(data, selectedTactic || null);
        })
      );
    }
  }

  private processTacticsAndTechniques(data: any[], selectedTactic: string | null): { tactics: any[], techniques: any[] } {
    const tactics = data.map(section => ({
      name: section.section,
      checked: section.section === selectedTactic
    }));

    let techniques: any[] = [];
    if (selectedTactic) {
      const selectedSection = data.find(section => section.section === selectedTactic);
      if (selectedSection) {
        techniques = Object.keys(selectedSection.subSection).map(key => ({
          name: key,
          checked: false
        }));
      }
    }
    return { tactics, techniques };
  }


  uploadLogs(paramsObj?: { [key: string]: string }) {
    let params = new HttpParams();
    if (paramsObj) {
        for (const key in paramsObj) {
            if (paramsObj.hasOwnProperty(key) && paramsObj[key]) {
                params = params.set(key, paramsObj[key]);
            }
        }
    }
    return this.http.get<{ data: string[] }>(`utils/pre-signed-url`, { observe: 'response', params }).pipe(
      map(({ body }) => {
        return body?.data || [];
      })
    );
  }

  uploadFile(preSignedUrl: string, file: File): Observable<any> {
    const formData = new FormData();
    formData.append('file', file);
    return this.http.put(preSignedUrl, formData);
  }

  parseLabData(body:any): Observable<any> {
    return this.http.post<{ data: string[]; }>(`labs/parse-text-to-scenario`, body).pipe(
      map(({ data }) => {
        return data;
      }),
      catchError(error => {
        console.error('Error occurred:', error);
        return throwError(() => new Error(error?.error?.msg ? error.error.msg :  'Failed to parse data.'));
      })
    );
  }

  generateDescription(body:any): Observable<any> {
    return this.http.post<{ data: string[]; }>(`labs/generate-short-description`, body).pipe(
      map(({ data }) => {
        return data;
      }),
      catchError(error => {
        console.error('Error occurred:', error);
        return throwError(() => new Error(error?.error?.msg ? error.error.msg :  'Failed to parse data.'));
      })
    );
  }

  parseQuestionData(body:any): Observable<any> {
    return this.http.post<{ data: string[]; }>(`labs/parse-text-to-questions`, body).pipe(
      map(({ data }) => {
        return data;
      }),
      catchError(error => {
        console.error('Error occurred:', error);
        return throwError(() => new Error(error?.error?.msg ? error.error.msg :  'Failed to parse data.'));
      })
    );
  }

  getRoles(){
    return this.http.get<{ data: string[] }>(`utils/wrl-roles`).pipe(
      map(({ data }) => {
        return data;
      })
    );
  }

  trustScore(invitationId:string,assessmentId:string){
    return this.http.put<string[]>(`evaluation/trust-score/${invitationId}/assessment/${assessmentId}`,{})
  }

}
